<template>
	<div>
		<el-dialog title="学生参与报告" :visible.sync="showDialog" width="90%" :close-on-click-modal="false"
			custom-class="width_800 cus_dialog" @close="closeDialog">

			<div style="text-align:right">
				<el-button size="small" icon="el-icon-download" type="primary" @click="exportXls()"
					style="margin-left: 100px;">导出答题结果
				</el-button>
				<el-button size="small" icon="el-icon-download" type="primary" @click="exportNojoinXls()"
					style="margin-left: 100px;">导出未参与人员名单
				</el-button>
				
			</div>

			<el-table v-tableFit border stripe :data="DataList" style="width: 100%; margin-top: 20px; color: #000">
				<el-table-column type="index" label="序号" width="60"></el-table-column>
				
				<el-table-column prop="class_name" label="班级" width="150"></el-table-column>
				<el-table-column prop="grade_name" label="专业/届次" min-width="120"></el-table-column>
				<el-table-column prop="yx_name" label="系部" min-width="120"></el-table-column>
				<el-table-column prop="count" label="班级人数" width="200"></el-table-column>
				<el-table-column prop="joined" label="已参与人数" width="200">
					<template slot-scope="scope">
						<div style="cursor: pointer; color: blue;" @click="showNoJoinDetail(scope.row)">{{ scope.row.joined }}</div>

					</template>
				</el-table-column>
				<el-table-column prop="notjoin" label="未参与人数" width="200">

					<template slot-scope="scope">
						<div style="cursor: pointer; color: blue;" @click="showNoJoinDetail(scope.row)">{{ scope.row.notjoin }}</div>

					</template>
				</el-table-column>

				<el-table-column prop="end_time" label="完成率" width="200">

					<template slot-scope="scope">
						{{ Math.round(scope.row.joined / scope.row.count * 10000) / 100 }}%

					</template>
				</el-table-column>

			</el-table>


			<div id="dkchartBox" style="height:400px"></div>

		</el-dialog>


		<el-dialog title="班级学生参与报告" :visible.sync="showUserDialog" width="90%" :close-on-click-modal="false"
			custom-class="width_800 cus_dialog" >

			<el-table v-tableFit border stripe :data="StuList" style="width: 100%; margin-top: 20px; color: #000">
				<el-table-column type="index" label="序号" width="60"></el-table-column>
				<el-table-column prop="stu_name" label="姓名" width="120"></el-table-column>
				<el-table-column prop="stu_code" label="学号" width="200"></el-table-column>
				<el-table-column prop="phone" label="手机号" width="200"></el-table-column>
				<el-table-column prop="class_name" label="班级" width="200"></el-table-column>
				<el-table-column prop="jc_name" label="专业/届次" min-width="120"></el-table-column>
				<el-table-column prop="yx_name" label="系部" min-width="120"></el-table-column>
				
				<el-table-column prop="end_time" label="是否参与" width="120">
					<template slot-scope="scope">
						<span v-if="scope.row.joinid" style="color:greenyellow">已参与</span>
						<span v-else style="color: orangered;">未参与</span>

					</template>
				</el-table-column>

			</el-table>


		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ["plan"],
	data() {
		return {
			showDialog: true,
			showUserDialog:false,
			DataList: [],
			StuList:[]

		}
	},
	mounted() {
		this.getList()

	},
	methods: {
		getList() {
			this.$http.post("/api/report_joininfo", { plan_id: this.plan.id }).then(res => {
				let classes = [];
				for (let item of res.data) {
					let ext = false
					for (let et of classes) {
						if (et.class_name == item.class_name) {
							ext = true
							if (item.joined) {
								et.joined = item.n
							}
							if (item.notjoin) {
								et.notjoin = item.n
							}
							break
						}
					}
					if (!ext) {
						let ndata = {
							class_id: item.class_id,
							class_name: item.class_name,
							grade_name: item.grade_name,
							yx_name: item.yx_name,
							joined: item.joined ? item.n : 0,
							notjoin: item.joined ? 0 : item.n,
						}
						classes.push(ndata)
					}
				}
				for (let item of classes) {
					item.count = item.joined + item.notjoin
					item.rate = Math.round(item.joined /item.count*10000)/100
				}
				this.DataList = classes
				this.$nextTick(e=>{
					this.initChart()
				})
			})
		},
		closeDialog() {
			this.$$parent(this,"showJoinReport",false)
		},
		showNoJoinDetail(row){
			
			this.$http.post("/api/ivst_class_nojoin",{class_id:row.class_id,plan_id:this.plan.id}).then(res=>{
				this.StuList = res.data
				this.showUserDialog = true
			})
			
		},
		initChart() {

			let dataAxis = this._lo.map(this.DataList, 'class_name')

			let data = this._lo.map(this.DataList, 'rate')
		
			let option = {
				title: {
					text: '班级问卷参与率(%)',
				},
				grid: { containLabel: true, },
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'line' // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: function (params) {

						if (params.length > 0) {
							var result = params[0].name + "<br>";
							params.forEach(function (item) {
								result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
							});
							return result;
						}
					},
				},
				xAxis: {
					data: dataAxis,
					type: 'category',
					axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
					axisTick: { show: true },
					axisLine: { show: true },
					z: 10
				},
				yAxis: {
					max: 100,//最大值
					min: 0,//最小值
					interval: 10,//间隔
					axisLine: { show: true },
					axisTick: { show: true },
					axisLabel: { color: '#000' }
				},
				series: [
					{
						type: 'bar',
						barMaxWidth: 30,
						itemStyle: {
							color: '#33a9ff',
							// color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
							//   { offset: 0, color: '#83bff6' },
							//   { offset: 0.5, color: '#188df0' },
							//   { offset: 1, color: '#188df0' }
							// ])
						},
						emphasis: {
							itemStyle: {
								color: '#33a9ff',
								// color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
								//   { offset: 0, color: '#2378f7' },
								//   { offset: 0.7, color: '#2378f7' },
								//   { offset: 1, color: '#83bff6' }
								// ])
							}
						},
						data: data,

						label: {
							show: true,
							position: 'inside',
							// formatter: function (params) { //标签内容
							//   return params.value + '%'
							// },
						},
					}
				],
			};

			let myChart = this.$echarts.init(document.getElementById('dkchartBox'));
			myChart.setOption(option)

		},

		exportXls(){
			this.$http.post("/api/ivst_dati_export",{plan_id: this.plan.id}).then(res=>{
				if(res.data.url){
					window.open(res.data.url,"_blank")
				}
			})
		},
		exportNojoinXls(){
			this.$http.post("/api/ivst_nojoinusers_export",{plan_id: this.plan.id,all:1}).then(res=>{
				if(res.data.url){
					window.open(res.data.url,"_blank")
				}
			})
		}

	},
}
</script>