<template>
	<div>
		<el-dialog title="企业参与报告" :visible.sync="showDialog" width="90%" :close-on-click-modal="false"
			custom-class="width_800 cus_dialog" @close="closeDialog">

			<div style="text-align:right">
				<el-button size="small" icon="el-icon-download" type="primary" @click="exportXls()"
					style="margin-left: 100px;">导出答题结果
				</el-button>
				
				
			</div>

			<el-table v-tableFit border stripe :data="DataList" style="width: 100%; margin-top: 20px; color: #000">
				<el-table-column type="index" label="序号" width="60"></el-table-column>
				
				<el-table-column prop="d5" label="企业名称" min-width="150"></el-table-column>
				<el-table-column prop="d6" label="企业类型" width="150"></el-table-column>
				<el-table-column prop="d7" label="行业" min-width="150"></el-table-column>
				<el-table-column prop="d8" label="地区" min-width="150"></el-table-column>
				<el-table-column prop="d9" label="规模" min-width="150"></el-table-column>
				<el-table-column prop="d16" label="联系人" min-width="150"></el-table-column>
				

				<el-table-column prop="d4" label="参与时间" width="200">

					
				</el-table-column>

			</el-table>


		
		</el-dialog>


		<el-dialog title="班级学生参与报告" :visible.sync="showUserDialog" width="90%" :close-on-click-modal="false"
			custom-class="width_800 cus_dialog" >

			<el-table v-tableFit border stripe :data="StuList" style="width: 100%; margin-top: 20px; color: #000">
				<el-table-column type="id" label="序号" width="60"></el-table-column>
				<el-table-column prop="d5" label="企业名称" width="120">
					
				</el-table-column>
				<el-table-column prop="stu_code" label="学号" width="200"></el-table-column>
				<el-table-column prop="phone" label="手机号" width="200"></el-table-column>
				<el-table-column prop="class_name" label="班级" width="200"></el-table-column>
				<el-table-column prop="jc_name" label="专业/届次" min-width="120"></el-table-column>
				<el-table-column prop="yx_name" label="系部" min-width="120"></el-table-column>
				
				<el-table-column prop="end_time" label="是否参与" width="120">
					<template slot-scope="scope">
						<span v-if="scope.row.joinid" style="color:greenyellow">已参与</span>
						<span v-else style="color: orangered;">未参与</span>

					</template>
				</el-table-column>

			</el-table>


		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ["plan"],
	data() {
		return {
			showDialog: true,
			showUserDialog:false,
			DataList: [],
			StuList:[]

		}
	},
	mounted() {
		this.getList()

	},
	methods: {
		getList() {

			


			this.$http.post("/api/ivst_dati_export", { plan_id: this.plan.id,onlydata:1 }).then(res => {
				let classes = [];
				for(let row of res.data){
					let rowdata={}
					for(let i = 0;i<row.length;i++){
						rowdata["d"+i] = row[i]
					}
					classes.push(rowdata)
				}
				
				this.DataList = classes
				this.$nextTick(e=>{
					//this.initChart()
				})
			})
		},
		closeDialog() {
			
			this.$$parent(this,"showJoinReportEnt",false)
		},
		showNoJoinDetail(row){
			
			this.$http.post("/api/ivst_class_nojoin",{class_id:row.class_id,plan_id:this.plan.id}).then(res=>{
				this.StuList = res.data
				this.showUserDialog = true
			})
			
		},
		initChart() {

			let dataAxis = this._lo.map(this.DataList, 'class_name')

			let data = this._lo.map(this.DataList, 'rate')
		
			let option = {
				title: {
					text: '班级问卷参与率(%)',
				},
				grid: { containLabel: true, },
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'line' // 默认为直线，可选为：'line' | 'shadow'
					},
					formatter: function (params) {

						if (params.length > 0) {
							var result = params[0].name + "<br>";
							params.forEach(function (item) {
								result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
							});
							return result;
						}
					},
				},
				xAxis: {
					data: dataAxis,
					type: 'category',
					axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
					axisTick: { show: true },
					axisLine: { show: true },
					z: 10
				},
				yAxis: {
					max: 100,//最大值
					min: 0,//最小值
					interval: 10,//间隔
					axisLine: { show: true },
					axisTick: { show: true },
					axisLabel: { color: '#000' }
				},
				series: [
					{
						type: 'bar',
						barMaxWidth: 30,
						itemStyle: {
							color: '#33a9ff',
							// color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
							//   { offset: 0, color: '#83bff6' },
							//   { offset: 0.5, color: '#188df0' },
							//   { offset: 1, color: '#188df0' }
							// ])
						},
						emphasis: {
							itemStyle: {
								color: '#33a9ff',
								// color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
								//   { offset: 0, color: '#2378f7' },
								//   { offset: 0.7, color: '#2378f7' },
								//   { offset: 1, color: '#83bff6' }
								// ])
							}
						},
						data: data,

						label: {
							show: true,
							position: 'inside',
							// formatter: function (params) { //标签内容
							//   return params.value + '%'
							// },
						},
					}
				],
			};

			let myChart = this.$echarts.init(document.getElementById('dkchartBox'));
			myChart.setOption(option)

		},

		exportXls(){
			this.$http.post("/api/ivst_dati_export",{plan_id: this.plan.id}).then(res=>{
				if(res.data.url){
					window.open(res.data.url,"_blank")
				}
			})
		},
		exportNojoinXls(){
			this.$http.post("/api/ivst_nojoinusers_export",{plan_id: this.plan.id,all:1}).then(res=>{
				if(res.data.url){
					window.open(res.data.url,"_blank")
				}
			})
		}

	},
}
</script>